import React, { useState } from 'react';
import './App.css';


import { ThemeProvider } from '@mui/material/styles';
import { AddThemeCallback, CurrentTheme, RemoveThemeCallback } from './master/theme';
import CssBaseline from '@mui/material/CssBaseline';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import { Box, Button, Link, Stack, ThemeOptions } from '@mui/material';
import { IInstallPromptEvent } from './master/installEvent';
import { CharacterPage } from './pages/CharacterPage';

import Logo from './images/fragged_empire2_logo.png';
import { BrowserRouter, Route, Routes, useNavigate } from 'react-router-dom';
import HomePage from './pages/home';
import ImportCharacterPage from './pages/ImportCharacter';


const MainToolbar = () => {
  const navigate = useNavigate();
  return <AppBar position="relative">
    <Toolbar>
      <img onClick={() => navigate("/")} src={Logo} height="50px" style={{cursor: "pointer"}} />
      <Box />
    </Toolbar>
  </AppBar>
}

function App() {
  const [ theme, setTheme ] = useState<ThemeOptions>(CurrentTheme());


  React.useEffect(() => {
    AddThemeCallback(setTheme);

    return () => {
      RemoveThemeCallback(setTheme);
    };
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <BrowserRouter>
        <MainToolbar />
        <main>
          <Routes>
            <Route path='/character/import' element={<ImportCharacterPage />} />
            <Route path='/character/:characterKey' element={<CharacterPage />} />
            <Route index element={<HomePage />} />
          </Routes>
        </main>
      </BrowserRouter>
      <footer>
          <Box sx={{ textAlign: "center"}} marginTop={2}>
            <Stack spacing="2">
              <Typography variant='body2'>
                <Link href={"https://fraggedempire.com"}>Fragged Empire</Link> is a registered trade mark of Wade Dyer (Design Ministries). 
              </Typography>
              <Typography variant='body2'>App by Design Ministries &amp; Scale Bound Games.</Typography>
              <Typography variant="body2">Version 0.3.1</Typography>
            </Stack>
          </Box>
      </footer>
    </ThemeProvider>
  );
}

export default App;
